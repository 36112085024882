type Environments =
  | "Development"
  | "Local"
  | "PreProduction"
  | "PreProduction-Staging"
  | "Production"
  | "Production-Staging"
  | "QA"
  | "QA-Staging";

interface AppSettings {
  B2CClientId: string;
  B2CScope: string;
  B2CScopeBasePath: string;
  B2CUri: string;
  CommunautoIdSrv: string;
  CommunautoReservautoMVCUri: string;
  Env: Environments;
  Nonce: string;
  ReservautoLegacyUris: { Default: string } & Partial<Record<string, string>>;
  ReservautoReactUri: string;
  RestAPIBackOfficeUri: string;
  SubscriptionMVCUri: string;
}

declare global {
  interface Window {
    communautoAppSettings: AppSettings;
  }
}

const appSettings = {
  ...window.communautoAppSettings,
  isStaging: window.communautoAppSettings.Env.endsWith("-Staging"),
};
export default appSettings;
